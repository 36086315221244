import Footer from '@/components/ui/shared/footer'
import NavigationBar from '@/components/ui/shared/navbar'
import { router, usePage } from '@inertiajs/react'
import { PropsWithChildren, useState } from 'react'
import React, { useEffect, FC, ReactNode } from 'react'

import klaroConfig from '@/json/klaro-config.js'
import { trackpageVisit } from '@/lib/events'
// @ts-ignore
import * as Klaro from 'klaro/dist/klaro-no-css'

// Initialize Klaro!
Klaro.setup(klaroConfig)
// klaro.show()

const PIXEL_ID = '1043515134029833'

interface IMenuItem {
    key: string
    href: string
    label: string
    requiresAuth: boolean
}

router.on('navigate', event => {
    // console.log(`Navigated to ${event.detail.page.url}`)
    trackpageVisit(event.detail.page.url)
})

const menuItems = ['Profile', 'My Settings', 'Log Out']
const menuItemsCollection: IMenuItem[] = [
    {
        key: 'profile',
        href: '/profile',
        requiresAuth: true,
        label: 'Profile'
    },
    // {
    //     key: 'giveaway-create',
    //     href: '/giveaway/create',
    //     requiresAuth: false,
    //     label: 'Create Giveaway'
    // },
    {
        key: 'logout',
        href: '/logout',
        requiresAuth: true,
        label: 'Log Out'
    }
]

const GeneralLayout = ({ children }: PropsWithChildren<{}>) => {
    const [showingNavigationDropdown, setShowingNavigationDropdown] =
        useState(false)
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const { auth, appName }: any = usePage().props

    useEffect(() => {
        // Facebook Pixel Code
        ;(function (f: any, b: any, e: any, v: any, n?: any, t?: any, s?: any) {
            if (f.fbq) return
            n = f.fbq = function () {
                n.callMethod
                    ? n.callMethod.apply(n, arguments)
                    : n.queue.push(arguments)
            }
            if (!f._fbq) f._fbq = n
            n.push = n
            n.loaded = !0
            n.version = '2.0'
            n.queue = []
            t = b.createElement(e)
            t.async = !0
            t.src = v
            s = b.getElementsByTagName(e)[0]
            s.parentNode.insertBefore(t, s)
        })(
            window,
            document,
            'script',
            'https://connect.facebook.net/en_US/fbevents.js'
        )

        window.fbq('init', PIXEL_ID)
        window.fbq('track', 'PageView')
        // End Facebook Pixel Code
    }, [])

    return (
        <div className='light dark:dark bg-background text-foreground'>
            <noscript>
                <img
                    height='1'
                    width='1'
                    style={{ display: 'none' }}
                    src={`https://www.facebook.com/tr?id=${PIXEL_ID}&ev=PageView&noscript=1`}
                />
            </noscript>
            <NavigationBar />

            <main className=''>{children}</main>
            <div className='bg-blue-200'>
                {/* <CookieConsent
                    location='bottom'
                    buttonText='Agree'
                    declineButtonText='Decline'
                    cookieName='cookieConsent'
                    ButtonComponent={({ children, ...rest }: any) => {
                        console.log('o,ega', children)
                        return (
                            <Button color='primary' {...rest}>
                                {children}
                            </Button>
                        )
                    }}
                    containerClasses='!bg-primary'
                    buttonClasses=''
                    declineButtonClasses=''
                    contentClasses=''
                    expires={150}
                    onAccept={() => {}}
                >
                    We use cookies to personalize content and ads, to provide
                    social media features, and to analyze our traffic.
                </CookieConsent> */}
            </div>

            <Footer />
        </div>
    )
}

export default GeneralLayout
