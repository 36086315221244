import { SVGAttributes } from 'react'

const Logo =
    'https://images.ctfassets.net/p2mu1i7m65u2/5DfmhrJkbkm2k2HOZ0piXY/a7a5b74b8d1c5b5e3d2b74b9550240fd/kecs_logo.png'

export default function ApplicationLogo ({ className }: any) {
    return (
        <div>
            <img className={className} src={Logo} />
        </div>
    )
}
