import ReactGa from "react-ga4";

interface ITrackedEvent {
    name: string;
    params: object;
}

const track = (ev: ITrackedEvent) => {
    ReactGa.event(ev.name, ev.params);
    // console.log("Track Google Event:", ev);
};

export const trackContactFormSubmit = (email: string) => {
    const ev: ITrackedEvent = {
        name: "submit_contact_form",
        params: {
            content_type: "form",
            content_id: `${email}`,
        },
    };

    track(ev);
};

export const trackpageVisit = (page: string) => {
    const ev: ITrackedEvent = {
        name: "page_view",
        params: {
            page_path: page,
        },
    };

    track(ev);
};
