import './bootstrap'
import '../css/app.css'
import 'klaro/dist/klaro.css'
import '../css/klaro-custom.css'
import 'leaflet/dist/leaflet.css'
import 'video.js/dist/video-js.css'

import { createRoot } from 'react-dom/client'
import { createInertiaApp } from '@inertiajs/react'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import GeneralLayout from './Layouts/general-layout'
import { NextUIProvider } from '@nextui-org/react'
import ReactDOM from 'react-dom/client'
import { AnalyticsProvider } from './context/analytics-context'
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react'

const metaEnv = import.meta.env
const appName = import.meta.env.VITE_APP_NAME || 'Site Name'

createInertiaApp({
    // title: title => `${title} - ${appName}`,
    title: title => `${title}`,
    // resolve: name =>
    //     resolvePageComponent(
    //         `./Pages/${name}.tsx`,
    //         import.meta.glob('./Pages/**/*.tsx')
    //     ),
    resolve: name => {
        const page = resolvePageComponent(
            `./Pages/${name}.tsx`,
            import.meta.glob('./Pages/**/*.tsx')
        )

        page.then((module: any) => {
            // module.default.layout = module.default.layout || GeneralLayout

            module.default.layout =
                module.default.layout ||
                ((module: any) => (
                    <NextUIProvider>
                        <GeneralLayout children={module} />
                    </NextUIProvider>
                ))
        })

        return page
    },
    setup ({ el, App, props }) {
        const root = createRoot(el)

        const inertiaProps: any = props?.initialPage?.props

        root.render(
            <AnalyticsProvider session_id={inertiaProps?.session_id as any}>
                <ContentfulLivePreviewProvider locale='en-US'>
                    <App {...props} />
                </ContentfulLivePreviewProvider>
            </AnalyticsProvider>
        )
    },
    progress: {
        color: '#4B5563'
    }
})
