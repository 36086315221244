import React, { useState } from 'react'
import ApplicationLogo from '@/components/ApplicationLogo'
import { BiSearch } from 'react-icons/bi'
import { User } from '@/types'
import {
    Navbar,
    NavbarBrand,
    NavbarContent,
    NavbarItem,
    Link,
    Input,
    DropdownItem,
    DropdownTrigger,
    Dropdown,
    DropdownMenu,
    Avatar,
    Button,
    NavbarMenuToggle
} from '@nextui-org/react'
import { NavbarMenu, NavbarMenuItem } from '@nextui-org/react'

import { usePage } from '@inertiajs/react'
import { Link as InertiaLink, InertiaLinkProps } from '@inertiajs/react'

interface IMenuItem {
    key: string
    href: string
    label: string
    requiresAuth: boolean
}

const menuItemsCollection: IMenuItem[] = [
    {
        key: 'profile',
        href: '/profile',
        requiresAuth: true,
        label: 'Profile'
    },
    // {
    //     key: 'giveaway-create',
    //     href: '/giveaway/create',
    //     requiresAuth: false,
    //     label: 'Create Giveaway'
    // },
    {
        key: 'logout',
        href: '/logout',
        requiresAuth: true,
        label: 'Log Out'
    }
]

const NavigationBar = () => {
    const { auth, appName }: any = usePage().props
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    return (
        <Navbar
            isBordered={true}
            isMenuOpen={isMenuOpen}
            onMenuOpenChange={setIsMenuOpen}
            maxWidth={'2xl'}
            height={'6rem'}
        >
            <NavbarContent justify='start'>
                <NavbarMenuToggle
                    aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
                    className='sm:hidden'
                />

                <NavbarBrand className='mr-4'>
                    <Link href='/'>
                        <ApplicationLogo className='lg:h-8 h-4 w-full' />
                    </Link>
                </NavbarBrand>
                <NavbarContent className='hidden sm:flex gap-3'>
                    <NavbarItem isActive>
                        <Link href='/' aria-current='page' color='secondary'>
                            Home
                        </Link>
                    </NavbarItem>
                </NavbarContent>
            </NavbarContent>
            <NavbarContent
                as='div'
                className='items-center hidden'
                justify='end'
            >
                <Input
                    classNames={{
                        base: 'max-w-full sm:max-w-[10rem] h-10',
                        mainWrapper: 'h-full',
                        input: 'text-small',
                        inputWrapper:
                            'h-full font-normal text-default-500 bg-default-400/20 dark:bg-default-500/20'
                    }}
                    placeholder='Type to search...'
                    size='sm'
                    startContent={<BiSearch size={18} />}
                    type='search'
                />
            </NavbarContent>

            {/* loggded in user menu desktop */}
            <NavbarContent
                justify='end'
                className='hidden sm:flex gap-4 bg-background'
            >
                {(auth?.user as User) ? (
                    <Dropdown placement='bottom-end' className='bg-background'>
                        <DropdownTrigger>
                            <Avatar
                                isBordered
                                as='button'
                                className='transition-transform'
                                color='secondary'
                                name={auth?.user?.userName || ''}
                                size='sm'
                                src={
                                    auth?.user?.avatar || '/images/avatar2.png'
                                }
                            />
                        </DropdownTrigger>
                        <DropdownMenu
                            items={menuItemsCollection}
                            color='primary'
                            aria-label='Navigation Menu'
                            variant='flat'
                            className='bg-background'
                        >
                            {item => {
                                if (item.key === 'logout') {
                                    return (
                                        <DropdownItem
                                            key='logout'
                                            color='danger'
                                        >
                                            <InertiaLink
                                                method='post'
                                                href={route('logout')}
                                            >
                                                Log Out
                                            </InertiaLink>
                                        </DropdownItem>
                                    )
                                }
                                return (
                                    <DropdownItem
                                        key={item.key}
                                        color={
                                            item.key === 'delete'
                                                ? 'danger'
                                                : 'default'
                                        }
                                        className={
                                            item.key === 'delete'
                                                ? 'text-danger'
                                                : ''
                                        }
                                    >
                                        <InertiaLink href={item.href}>
                                            {item.label}
                                        </InertiaLink>
                                    </DropdownItem>
                                )
                            }}
                        </DropdownMenu>
                    </Dropdown>
                ) : (
                    <div className='flex gap-4'>
                        <NavbarItem className='hidden lg:flex'>
                            <Button
                                color='primary'
                                size='lg'
                                variant='solid'
                                as={Link}
                                href='/login'
                            >
                                Login
                            </Button>
                            {/* <Link color='primary' href='/login'>
                                    Login
                                </Link> */}
                        </NavbarItem>
                        <NavbarItem>
                            <Button
                                as={Link}
                                color='primary'
                                href='/register'
                                variant='flat'
                                size='lg'
                            >
                                Sign Up
                            </Button>
                        </NavbarItem>
                    </div>
                )}
            </NavbarContent>
            <NavbarMenu>
                {menuItemsCollection.map((item, index) => {
                    if (item.key === 'logout') {
                        return (
                            <NavbarMenuItem className='list-none' key='logout'>
                                <InertiaLink
                                    method='post'
                                    href={route('logout')}
                                >
                                    Log Out
                                </InertiaLink>
                            </NavbarMenuItem>
                        )
                    }
                    return (
                        <NavbarMenuItem
                            key={item.key}
                            // color={item.key === 'delete' ? 'danger' : 'default'}
                            className={
                                item.key === 'delete'
                                    ? 'text-danger list-none'
                                    : ' list-none'
                            }
                        >
                            <InertiaLink href={item.href}>
                                {item.label}
                            </InertiaLink>
                        </NavbarMenuItem>
                    )
                })}
            </NavbarMenu>
        </Navbar>
    )
}

export default NavigationBar
