import React, { FC, PropsWithChildren, createContext, useEffect } from 'react'
import ReactGa from 'react-ga4'

interface Props {
    session_id: string
}

export const AnalyticsContext = createContext(null)
export const AnalyticsProvider: FC<PropsWithChildren<Props>> = ({
    children,
    session_id
}) => {
    useEffect(() => {
        try {
            const config = import.meta.env
            // console.log('TEST ENV', config)
            ReactGa.initialize(config.VITE_GA_MEASUREMENT_ID, {
                gaOptions: {
                    userId: session_id
                }
            })
        } catch (error) {
            console.error(error)
        }

        return () => {}
    }, [])

    return (
        <AnalyticsContext.Provider value={null}>
            {children}
        </AnalyticsContext.Provider>
    )
}
