/*
By default, Klaro will load the config from a global 'klaroConfig' variable. You
can change this by specifying the 'data-klaro-config' attribute on your script:
<script src="klaro.js" data-klaro-config="myConfigVariableName"
*/
const klaroConfig = {
    elementID: "klaro",
    cssClass: "klaro",
    styling: {
        theme: ["klaor", "bottom", "wide"],

        button: "",
        learnMoreLink: "",
        modalTitle: "",
        modalDescription: "",
    },

    // styling: {
    //     theme: [
    //         "klaro-modal",
    //         "klaro-title",
    //         "klaro-description",
    //         "klaro-button",
    //         "klaro-learn-more",
    //     ],
    //     button: "klaro-button",
    //     learnMoreLink: "klaro-learn-more",
    //     modalTitle: "klaro-title",
    //     modalDescription: "klaro-description",
    // },
    lang: "en",
    default: true,
    noNotice: false,
    cookieName: "klaro-i18n",

    mustConsent: true,

    translations: {
        en: {
            privacyPolicyUrl: "/privacy-policy",
            consentNotice: {
                description: `Our website uses cookies to enhance your browsing experience, analyze site traffic, and personalize content. By clicking 'Accept', you consent to our use of cookies. You can manage your preferences and learn more about how we use cookies by clicking 'Settings'. You can always change or withdraw your consent later.`,
                learnMore: "Settings",
                testing: "Testing mode!",
            },
            privacyPolicy: {
                text: "To learn more, please read our {privacyPolicy}.",
                name: "Privacy Policy",
            },
            consentModal: {
                title: "We Value Your Privacy",
                description: "You can manage your preferences here.",
            },
            poweredBy: "Klaro",
            ok: "Accept",

            purposes: {
                analytics: "Analytics",
                security: "Security",
                livechat: "Livechat",
                styling: "Styling",
            },
            googleAnalytics: {
                title: "Google Analytics",
                description: "Collection of visitor statistics",
            },
            mouseflow: {
                description: "Real-time user analytics",
            },
        },
        de: {
            privacyPolicyUrl: "/datenschutz",
            consentNotice: {
                description: `Unsere Website verwendet Cookies, um Ihr Surferlebnis zu verbessern, den Website-Traffic zu analysieren und Inhalte zu personalisieren. Durch Klicken auf 'Akzeptieren' stimmen Sie unserer Verwendung von Cookies zu. Sie können Ihre Präferenzen verwalten und mehr darüber erfahren, wie wir Cookies verwenden, indem Sie auf 'Einstellungen' klicken. Sie können Ihre Zustimmung jederzeit ändern oder widerrufen.`,
                learnMore: "Einstellungen",
                testing: "Testmodus!",
            },
            privacyPolicy: {
                text: "Um mehr zu erfahren, lesen Sie bitte unsere {privacyPolicy}.",
                name: "Datenschutzrichtlinie",
            },
            consentModal: {
                title: "Wir schätzen Ihre Privatsphäre",
                description: "Hier können Sie Ihre Präferenzen verwalten.",
            },
            poweredBy: "Klaro",
            ok: "Akzeptieren",

            purposes: {
                analytics: "Analytik",
                security: "Sicherheit",
                livechat: "Livechat",
                styling: "Styling",
            },
            googleAnalytics: {
                title: "Google Analytics",
                description: "Sammlung von Besucherstatistiken",
            },
            mouseflow: {
                description: "Echtzeit-Benutzeranalysen",
            },
        },
    },
    services: [
        {
            name: "googleAnalytics",
            purposes: ["analytics"],
            callback: function (consent, app) {
                console.log("analytics!");
            },
        },
        {
            name: "tailwind",
            title: "Tailwind CSS (external resource)",
            description: "External stylesheet",
            purposes: ["styling"],
        },
        {
            name: "mouseflow",
            title: "Mouseflow",
            purposes: ["analytics"],
        },
    ],
    elementID: "klaro",
    storageMethod: "cookie",
    storageName: "klaro",
    cookieExpiresAfterDays: 30,
    mustConsent: false,
};

export default klaroConfig;
