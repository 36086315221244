import React from 'react'
import { Divider } from '@nextui-org/divider'
import ApplicationLogo from '@/components/ApplicationLogo'
import { usePage } from '@inertiajs/react'
import { FaInstagram } from 'react-icons/fa'
import { Link } from '@nextui-org/react'
import { FaTiktok } from 'react-icons/fa'
import { FaYoutube } from 'react-icons/fa'
import { FaSpotify } from 'react-icons/fa'
import { FaApple } from 'react-icons/fa'

const Footer = () => {
    const { appName, auth, title } = usePage().props
    const year = new Date().getFullYear()
    const siteName: any = appName

    return (
        <footer className='bg-background-800 border-t-1'>
            <Divider className='my-4 text-secondary' />
            <div className='app-spacing flex flex-col space-y-6 items-center justify-items-center justify-center'>
                <div className='w-40 md:w-60 relative'>
                    <ApplicationLogo />
                </div>
                <div className=' h-full'>
                    <div className='mt-4 flex w-full justify-items-center justify-center items-center gap-4'>
                        <Link
                            href='https://instagram.com/kecsdbeats'
                            rel='noreferrer'
                            target='_blank'
                            className='text-foreground bg-white hover:bg-warning h-8 w-8 rounded-full flex p-1 transition hover:text-foreground/75'
                        >
                            <span className='sr-only'>Instagram</span>
                            <FaInstagram className='w-full h-full' />
                        </Link>
                        <Link
                            href='https://tiktok.com/@kecsdbeats'
                            rel='noreferrer'
                            target='_blank'
                            className='text-foreground bg-white hover:bg-warning h-8 w-8 rounded-full flex p-1 transition hover:text-foreground/75'
                        >
                            <span className='sr-only'>Tiktok</span>
                            <FaTiktok className='w-full h-full' />
                        </Link>
                        <Link
                            href='https://open.spotify.com/artist/5sEM75al9e2w4L7NtzzXUn'
                            rel='noreferrer'
                            target='_blank'
                            className='text-foreground bg-white hover:bg-warning h-8 w-8 rounded-full flex p-1 transition hover:text-foreground/75'
                        >
                            <span className='sr-only'>Spotify</span>
                            <FaSpotify className='w-full h-full' />
                        </Link>
                    </div>

                    <ul className='mt-12 flex flex-wrap justify-center gap-6 md:gap-8 lg:gap-12 list-none'>
                        <li className='list-none'>
                            <a
                                className='text-foreground transition hover:text-foreground/75'
                                href='#'
                            >
                                {' '}
                                Privacy Policy{' '}
                            </a>
                        </li>

                        <li className='list-none'>
                            <a
                                className='text-foreground transition hover:text-foreground/75'
                                href='#'
                            >
                                {' '}
                                Terms{' '}
                            </a>
                        </li>

                        <li className='list-none'>
                            <a
                                className='text-foreground transition hover:text-foreground/75'
                                href='#'
                            >
                                {' '}
                                Cookie Policy{' '}
                            </a>
                        </li>
                    </ul>
                </div>

                <span className='text-xs lg:text-sm max-w-7xl m-auto py-2'>
                    &copy; {year} {siteName} | All Right Reserved.
                </span>
            </div>
        </footer>
    )
}

export default Footer
